<template>
    <NavbarCompSecondary/>
    <div class="bg-blue-500">
        <div class="flex items-center justify-center">
            <div class="grid md:grid-cols-2 gap-4 mx-4 mt-5 mb-5">
                <div class="my-5 flex items-center justify-center">
                    <PricingSVG/>
                </div>
                <div class="">
                    <a href="#" class="flex flex-row items-center h-full">
                        <div class="flex flex-col justify-between p-4 leading-normal w-full">
                            <h5 class="mb-3 text-5xl font-bold tracking-tight text-white text-center">Our Pricing</h5>
                            <h5 class="mb-3 text-xl font-bold tracking-tight text-white text-center">We Provide the Cheapest Travel</h5>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="mb-10 mt-10">
        <p class="text-4xl text-center mb-10 font-bold" style="color: #1C3F60">Once in a lifetime Promos!</p>
        <section id="feature">
            <div class="flex items-center justify-center">
                <div class="grid grid-cols-1 sm:grid-cols-3 sm:gap-10 gap-4">
                    <!-- 1 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover h-48 w-96" src="https://img.freepik.com/free-vector/sales-promotion-cartoon-web-icon-marketing-strategy-rebate-advertising-discount-offer-low-price-idea-clearance-sale-customer-attraction-vector-isolated-concept-metaphor-illustration_335657-2752.jpg" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white">
                            <div class="font-bold text-xl mb-2">Package Deals and Discounts</div>
                            <ul class="text-slate-800 text-base">
                                <li>
                                    <b>Early Bird Savings:</b> Secure your dream getaway by booking in advance! Enjoy exclusive discounts when you plan ahead with our early booking promotions. The sooner you book, the more you save. Your dream vacation is just a click away!
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- End -->

                    <!-- 2 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover h-48 w-96" src="https://img.freepik.com/free-vector/money-transfer-abstract-concept-illustration_335657-3868.jpg" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white">
                            <div class="font-bold text-xl mb-2">Loyalty Programs and Referral Bonuses</div>
                            <ul class="text-slate-800 text-base">
                                <li>
                                    <b>Loyalty Points Boost:</b> Your journey with us is more rewarding than ever! Earn loyalty points with every booking and watch your savings grow. Redeem points for discounts, upgrades, or special perks on your future travels. Because every adventure deserves a little extra!
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- End -->

                    <!-- 3 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover h-48 w-96" src="https://img.freepik.com/free-vector/festive-calendar-event-holiday-celebration-party-work-schedule-planning-project-management-deadline-idea-office-managers-excited-colleagues_335657-1610.jpg" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white">
                            <div class="font-bold text-xl mb-2">Seasonal Promotions and Special Events</div>
                            <ul class="text-slate-800 text-base">
                                <li>
                                    <b>Special Occasion Bonuses:</b> Birthdays, anniversaries, or just because – celebrate with us and enjoy exclusive discounts or perks tailored for your special moments. Your milestones deserve to be celebrated in style. Let's make your next trip extraordinary!
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- End -->
                </div>
            </div>
        </section>

        <div id="ad_id">
            <amp-ad width="100vw" height="320"
                type="adsense"
                data-ad-client="ca-pub-5580517504444924"
                data-ad-slot="8950603964"
                data-auto-format="rspv"
                data-full-width="">
                <div overflow=""></div>
            </amp-ad>
        </div>
    </div>
    <PageFooter/>
</template>

<script>
import NavbarCompSecondary from '@/components/NavbarCompSecondary.vue';
import PricingSVG from '@/components/PricingSVG.vue'
import PageFooter from '@/components/PageFooter.vue'
import { useHead } from '@vueuse/head'

export default{
    components: { NavbarCompSecondary, PricingSVG, PageFooter },
    name: 'PricingPage',

    mounted(){
        const { meta } = this.$route;

        useHead({
            title: meta.title,
            meta: [
                { name: 'description', content: meta.description },
                { property: 'og:title', content: meta.ogTitle },
                { property: 'og:description', content: meta.ogDescription },
                { property: 'og:image', content: meta.ogImage },
                { property: 'og:url', content: meta.ogUrl },
            ],
        });
    },
}

</script>