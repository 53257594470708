<template>
    <NavbarComp></NavbarComp>
    <div class="bg-fixed bg-cover h-80 w-30" style="background-position: bottom; background-image: url('https://ik.imagekit.io/tvlk/blog/2016/12/Dinagyang-Festival.jpg?tr=dpr-2,w-675')">
    </div>
    <div class="mt-10 mx-10 mb-10">
        <h1 class="my-heading text-left text-3xl font-bold">Culture-Curious: Navigating the Mosaic of Global Traditions</h1>
        <p class="block text-justify text-black mt-5 mb-8">
            Traveling is not just about reaching destinations; it's a portal to the diverse tapestry of cultures 
            that adorn our world. The term "culture-curious" embodies the spirit of a traveler who is not merely 
            passing through places but actively seeking to understand, appreciate, and connect with the richness 
            of different societies.
        </p>
    </div>

    <div class="bg-yellow-400">
        <div class="flex items-center justify-center">
            <div class="grid md:grid-cols-2 gap-4 mx-4 mt-5 mb-5">
                <div class="my-5 flex items-center justify-center">
                    <ConnectedSVG/>
                </div>
                <div class="">
                    <a href="#" class="flex flex-row items-center h-full">
                        <div class="flex flex-col justify-between p-4 leading-normal w-full">
                            <h5 class="mb-3 text-5xl font-bold tracking-tight text-white text-center">Diversity Embracer</h5>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="grid md:grid-cols-2 gap-4 mx-4 md:mx-20 mt-10 mb-5">        
        <div class="w-full md:w-auto">
            <a href="#" class="flex flex-col items-center h-full bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100">
                <div class="flex flex-col justify-between p-4 leading-normal w-full">
                    <h5 class="mb-3 text-2xl font-bold tracking-tight text-gray-900">The Essence of Culture-Curious Travel</h5>
                    <p class="mb-3 mx-2 font-normal text-gray-700 text-justify">
                        Being culture-curious goes beyond snapping photos of famous landmarks; 
                        it's an immersive exploration into the heart of local customs, traditions, 
                        and ways of life. It's about being genuinely interested in the stories behind 
                        the places, understanding the significance of rituals, and respecting the 
                        uniqueness of every community encountered on the journey.
                    </p>
                </div>
            </a>
        </div>
        <div class="w-full md:w-auto">
            <a href="#" class="flex flex-col items-center h-full bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100">
                <div class="flex flex-col justify-between p-4 leading-normal w-full">
                    <h5 class="mb-3 text-2xl font-bold tracking-tight text-gray-900">Breaking Cultural Barriers</h5>
                    <p class="mb-3 mx-2 font-normal text-gray-700 text-justify">
                        One of the hallmarks of a culture-curious traveler is the ability to break cultural barriers. 
                        Whether it's trying regional delicacies, engaging in local festivities, or attempting to 
                        communicate in a new language, the culture-curious spirit thrives on embracing the unfamiliar. 
                        It's through these interactions that stereotypes are shattered, and a deeper appreciation for 
                        the diversity of our world emerges.
                    </p>
                </div>
            </a>
        </div>
        <div class="w-full md:w-auto">
            <a href="#" class="flex flex-col items-center h-full bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100">
                <div class="flex flex-col justify-between p-4 leading-normal w-full">
                    <h5 class="mb-3 text-2xl font-bold tracking-tight text-gray-900">Open-Minded Exploration</h5>
                    <p class="mb-3 mx-2 font-normal text-gray-700 text-justify">
                        A culture-curious traveler approaches each destination with an open mind. Rather than 
                        imposing preconceived notions, they eagerly embrace the unexpected. From participating 
                        in age-old ceremonies to learning traditional dances, every experience becomes an 
                        opportunity for personal growth and a chance to broaden one's perspective.
                    </p>
                </div>
            </a>
        </div>
        <div class="w-full md:w-auto">
            <a href="#" class="flex flex-col items-center h-full bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100">
                <div class="flex flex-col justify-between p-4 leading-normal w-full">
                    <h5 class="mb-3 text-2xl font-bold tracking-tight text-gray-900">Building Connections</h5>
                    <p class="mb-3 mx-2 font-normal text-gray-700 text-justify">
                        At the heart of being culture-curious is the desire to build connections with people from 
                        different walks of life. It's about forging bonds that transcend borders, sharing stories, 
                        and realizing the common threads that unite humanity. These connections not only create 
                        lasting memories but also contribute to a global tapestry of understanding and goodwill.
                    </p>
                </div>
            </a>
        </div>
        <div class="w-full md:w-auto">
            <a href="#" class="flex flex-col items-center h-full bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100">
                <div class="flex flex-col justify-between p-4 leading-normal w-full">
                    <h5 class="mb-3 text-2xl font-bold tracking-tight text-gray-900">Building Connections</h5>
                    <p class="mb-3 mx-2 font-normal text-gray-700 text-justify">
                        At the heart of being culture-curious is the desire to build connections with people from 
                        different walks of life. It's about forging bonds that transcend borders, sharing stories, 
                        and realizing the common threads that unite humanity. These connections not only create 
                        lasting memories but also contribute to a global tapestry of understanding and goodwill.
                    </p>
                </div>
            </a>
        </div>
        <div class="w-full md:w-auto">
            <a href="#" class="flex flex-col items-center h-full bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100">
                <div class="flex flex-col justify-between p-4 leading-normal w-full">
                    <h5 class="mb-3 text-2xl font-bold tracking-tight text-gray-900">Preserving and Celebrating Heritage</h5>
                    <p class="mb-3 mx-2 font-normal text-gray-700 text-justify">
                        Culture-curious travelers recognize the importance of preserving and celebrating cultural 
                        heritage. Whether it's exploring historical sites, visiting museums, or supporting local 
                        artisans, they actively contribute to the sustainability of cultural traditions. 
                        This mindful approach ensures that the beauty of diverse cultures endures for future 
                        generations.
                    </p>
                </div>
            </a>
        </div>

        <div id="ad_id">
            <amp-ad width="100vw" height="320"
                type="adsense"
                data-ad-client="ca-pub-5580517504444924"
                data-ad-slot="8950603964"
                data-auto-format="rspv"
                data-full-width="">
                <div overflow=""></div>
            </amp-ad>
        </div>
    </div>

    <div class="bg-blue-500">
        <div class=" flex items-center justify-center">
            <div class="grid md:grid-cols-2 gap-4 mx-4 md:mx-20 mt-10 mb-5">
                <div class="my-5">
                    <MissionSVG/>
                </div>
                <div class="">
                    <a href="#" class="flex flex-col items-center h-full">
                        <div class="flex flex-col justify-between p-4 leading-normal w-full">
                            <h5 class="mb-3 text-2xl font-bold tracking-tight text-white">Cultivating a Global Mindset: A Lifelong Journey</h5>
                            <p class="mb-3 mx-2 font-normal text-gray-700 text-justify text-white">
                                The culture-curious mindset extends beyond travel—it becomes a way of life. It's about integrating the lessons learned from different cultures into everyday living, fostering a global mindset that values inclusivity, tolerance, and appreciation for differences. This mindset becomes a powerful force in breaking down cultural divides and promoting a sense of unity among the people of the world.
                                <br> <br>In conclusion, being culture-curious is not a one-time event; it's a lifelong journey of exploration, understanding, and appreciation. It's about celebrating the unique stories that each culture tells and recognizing the shared humanity that unites us all. So, the next time you embark on a journey, don't just be a tourist—be culture-curious, and let the world's cultural mosaic unfold before you in all its vibrant splendor. Embrace this mindset not only in your travels but in every aspect of your life, and watch as it transforms your perspective and enriches your connection with the global community.
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <PageFooter/>
</template>

<script>

import NavbarComp from '@/components/NavbarCompSecondary.vue'
import PageFooter from '@/components/PageFooter.vue'
import MissionSVG from '@/components/MissionSVG.vue'
import ConnectedSVG from '@/components/ConnectedSVG.vue'
import { useHead } from '@vueuse/head'

export default{
    components: {NavbarComp, PageFooter, MissionSVG, ConnectedSVG },
    name: 'CultureCurious',

    mounted(){
        const { meta } = this.$route;

        useHead({
            title: meta.title,
            meta: [
                { name: 'description', content: meta.description },
                { property: 'og:title', content: meta.ogTitle },
                { property: 'og:description', content: meta.ogDescription },
                { property: 'og:image', content: meta.ogImage },
                { property: 'og:url', content: meta.ogUrl },
            ],
        });
    },
}

</script>