<template>
    <NavbarCompSecondary/>
    <div class="bg-yellow-400">
        <div class="flex items-center justify-center">
            <div class="grid md:grid-cols-2 gap-4 mx-4 mt-5 mb-5">
                <div class="my-5 flex items-center justify-center">
                    <ComingSoon/>
                </div>
                <div class="">
                    <a href="#" class="flex flex-row items-center h-full">
                        <div class="flex flex-col justify-between p-4 leading-normal w-full">
                            <h5 class="mb-3 text-5xl font-bold tracking-tight text-white text-center">Coming Soon</h5>
                            <h5 class="mb-3 text-xl font-bold tracking-tight text-white text-center">Journey Unveiling Soon!</h5>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="mb-10 mt-10">
        <p class="text-4xl text-center mb-10 font-bold" style="color: #1C3F60">The Future Unveiled!</p>
        <section id="feature">
            <div class="flex items-center justify-center">
                <div class="grid grid-cols-1 sm:grid-cols-3 sm:gap-10 gap-4">
                    <!-- 1 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover h-48 w-96" src="https://img.freepik.com/free-vector/car-rental-concept-illustration_114360-9267.jpg?size=626&ext=jpg&ga=GA1.1.87170709.1707264000&semt=ais" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white">
                            <div class="font-bold text-xl mb-2">Car Rental Services</div>
                            <p class="text-gray-700 text-base">
                                At AirProve, we're gearing up to offer personalized car rental services 
                                alongside our tailored travel experiences. From compact city cruisers 
                                to spacious family vehicles, we'll ensure your journey is as smooth on 
                                the road as it is in the air. Stay tuned for the freedom to explore your 
                                dream destinations at your own pace, coming soon.
                            </p>
                        </div>
                    </div>
                    <!-- End -->

                    <!-- 2 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover h-48 w-96" src="https://st.depositphotos.com/1031343/3675/v/450/depositphotos_36753593-stock-illustration-coming-soon-stamp.jpg" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white">
                            <div class="font-bold text-xl mb-2 animate-pulse text-gray-400">Coming Soon</div>
                            <div class="text-gray-700 text-base animate-pulse mt-5">
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                    <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                                </div>
                                <div class="h-2 bg-slate-200 rounded col-span-2 my-4"></div>
                                <div class="h-2 bg-slate-200 rounded col-span-2 my-4"></div>
                                <div class="h-2 bg-slate-200 rounded col-span-2 my-4"></div>
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                                    <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                </div>
                                <div class="h-2 bg-slate-200 rounded col-span-2 my-4"></div>
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End -->

                    <!-- 3 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover h-48 w-96" src="https://st.depositphotos.com/1031343/3675/v/450/depositphotos_36753593-stock-illustration-coming-soon-stamp.jpg" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white">
                            <div class="font-bold text-xl mb-2 animate-pulse text-gray-400">Coming Soon</div>
                            <div class="text-gray-700 text-base animate-pulse mt-5">
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                    <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                                </div>
                                <div class="h-2 bg-slate-200 rounded col-span-2 my-4"></div>
                                <div class="h-2 bg-slate-200 rounded col-span-2 my-4"></div>
                                <div class="h-2 bg-slate-200 rounded col-span-2 my-4"></div>
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                                    <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                </div>
                                <div class="h-2 bg-slate-200 rounded col-span-2 my-4"></div>
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End -->
                </div>
            </div>
        </section>

        <div id="ad_id">
            <amp-ad width="100vw" height="320"
                type="adsense"
                data-ad-client="ca-pub-5580517504444924"
                data-ad-slot="8950603964"
                data-auto-format="rspv"
                data-full-width="">
                <div overflow=""></div>
            </amp-ad>
        </div>
    </div>
    <PageFooter/>
</template>

<script>
import NavbarCompSecondary from '@/components/NavbarCompSecondary.vue';
import ComingSoon from '@/components/ComingSoon.vue'
import PageFooter from '@/components/PageFooter.vue'

export default{
    components: { NavbarCompSecondary, ComingSoon, PageFooter }
}

</script>