<template>
  <nav class="bg-white border-gray-200">
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="https://myairprove.com" class="flex items-center space-x-3 rtl:space-x-reverse">
            <img src="../assets/company_logo/Airprove-blue_logo-NOBG.png" class="w-16 h-16" alt="AirProve Logo" />
            <span class="self-center text-2xl text-slate-900 font-semibold whitespace-nowrap">AirProve</span>
        </a>
        <button @click="toggle" data-collapse-toggle="navbar-default" type="button"
          class="mx-2 inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
          </svg>
        </button>

        <div :class="open ? 'block' : 'hidden'" class="w-full md:block md:w-auto mx-2" id="navbar-default">
          <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 bg-transparent md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-transparent">
            <li>
              <a href="#" @click="goBack" class="block py-2 px-3 text-slate-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Home</a>
            </li>
            <li>
              <a href="#about" @click="goBack" class="block py-2 px-3 text-slate-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">About</a>
            </li>
            <li>
              <a @click="servicesPage" class="block py-2 px-3 text-slate-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Services</a>
            </li>
            <li>
              <a @click="pricingPage" class="block py-2 px-3 text-slate-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Pricing</a>
            </li>
            <li>
              <a href="#feedback" @click="goBack" class="block py-2 px-3 text-slate-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Feedback</a>
            </li>
            <li>
              <a @click="goBack" class="block py-2 px-3 text-slate-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Upcoming</a>
            </li>
            <li>
              <a href="#contact" class="block py-2 px-3 text-slate-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Contact</a>
            </li>
          </ul>
        </div>
      </div>
  </nav>
</template>

<script>

export default{
  data(){
    return {
      open: false,
    }
  },
  methods:{
    goBack(){
      this.$router.push({name: 'Home'});
    },

    servicesPage(){
      this.$router.push({name: 'services'})
    },

    pricingPage(){
      this.$router.push({name: 'pricing'})
    },

    toggle(){
      this.open = !this.open
    }
  }
}
</script>