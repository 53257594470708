<template>
    <NavbarComp></NavbarComp>
    <div class="bg-fixed bg-cover h-80 w-30" style="background-image: url('https://izi.travel/sites/default/files/inline-images/kal-visuals-aK4iPNYipnU-unsplash.jpg'); background-position: bottom;">
    </div>
    <div class="mt-10 mx-10 mb-10">
        <h1 class="my-heading text-left text-3xl font-bold">Relax, Relate, Release: A Guide to Embracing Serenity in a Busy World</h1>
        <p class="block text-justify text-black mt-5 mb-8">
            In the hustle and bustle of our fast-paced lives, finding moments of peace and tranquility has become 
            essential for our well-being. "Relax, Relate, Release" encapsulates the mantra for achieving inner 
            calm amidst the chaos. In this blog, we explore the significance of these three Rs and delve into 
            practical ways to incorporate them into our daily routines.
        </p>
    </div>

    <div class="bg-blue-500">
        <div class="flex items-center justify-center">
            <div class="grid md:grid-cols-2 gap-4 mx-4 mt-5 mb-5">
                <div class="my-5 flex items-center justify-center">
                    <RelaxSVG/>
                </div>
                <div class="">
                    <a href="#" class="flex flex-row items-center h-full">
                        <div class="flex flex-col justify-between p-4 leading-normal w-full">
                            <h5 class="mb-3 text-5xl font-bold tracking-tight text-white text-center">The Art of Relaxation</h5>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="mb-10 mt-10">
        <section id="feature">
            <div class="flex items-center justify-center">
                <div class="grid grid-cols-1 sm:grid-cols-3 sm:gap-10 gap-4">
                    <!-- 1 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover h-48 w-96" src="https://img.freepik.com/free-vector/businessman-character-put-huge-plug-into-socket-recharge-tired-exhausted-he-low-energy-percent-towork-working-burn-out-zero-accumulator-power-cartoon-people-vector-illustration_1150-58798.jpg" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white">
                            <div class="font-bold text-xl mb-2">Unplug to Recharge</div>
                            <p class="text-gray-700 text-base">
                                In a world dominated by screens and notifications, taking time to unplug is crucial. 
                                Designate moments in your day to disconnect from electronic devices. Whether it's a 
                                brief walk in nature, a few minutes of deep breathing, or enjoying a cup of tea 
                                without distractions, these small breaks can do wonders for your mental well-being.
                            </p>
                        </div>
                    </div>
                    <!-- End -->

                    <!-- 2 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover h-48 w-96" src="https://img.freepik.com/free-vector/user-practicing-mindfulness-meditation-lotus-pose-mindful-meditating-mental-calmness-self-consciousness-focusing-releasing-stress-concept-vector-isolated-illustration_335657-2250.jpg" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white">
                            <div class="font-bold text-xl mb-2">Mindful Practices</div>
                            <p class="text-gray-700 text-base">
                                Embrace mindfulness through practices like meditation and yoga. 
                                These techniques not only help you relax your body but also cultivate 
                                a sense of awareness and presence. Start with short sessions and gradually 
                                extend the time as you become more comfortable with the practice.
                            </p>
                        </div>
                    </div>
                    <!-- End -->

                    <!-- 3 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover h-48 w-96" src="https://img.freepik.com/free-vector/woman-relaxing-home-girl-drinking-hot-tea-reading-book-armchair-flat-vector-illustration-leisure-evening-literature_74855-8684.jpg" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white">
                            <div class="font-bold text-xl mb-2">Create a Calming Environment</div>
                            <p class="text-gray-700 text-base">
                                Designate a space at home or work where you can retreat for a few moments of 
                                tranquility. Add elements that soothe your senses, such as soft lighting, calming 
                                scents, and comfortable seating. Creating a serene environment provides an instant 
                                escape from the demands of the outside world.
                            </p>
                        </div>
                    </div>
                    <!-- End -->
                </div>
            </div>
        </section>

        <div id="ad_id">
            <amp-ad width="100vw" height="320"
                type="adsense"
                data-ad-client="ca-pub-5580517504444924"
                data-ad-slot="8950603964"
                data-auto-format="rspv"
                data-full-width="">
                <div overflow=""></div>
            </amp-ad>
        </div>
    </div>

    <div class="bg-yellow-400">
        <div class="flex items-center justify-center">
            <div class="grid md:grid-cols-2 gap-4 mx-4 mt-5 mb-5">
                <div class="my-5 flex items-center justify-center">
                    <MedidatingSVG/>
                </div>
                <div class="">
                    <a href="#" class="flex flex-row items-center h-full">
                        <div class="flex flex-col justify-between p-4 leading-normal w-full">
                            <h5 class="mb-3 text-5xl font-bold tracking-tight text-white text-center">Releasing Stress</h5>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="mb-10 mt-10">
        <section id="feature">
            <div class="flex items-center justify-center">
                <div class="grid grid-cols-1 sm:grid-cols-3 sm:gap-10 gap-4">
                    <!-- 1 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover h-48 w-96" src="https://img.freepik.com/premium-vector/business-woman-meditate-calm-emotions-healing-body-mind-office-girl-control-stress-yoga-meditation-balance-vector-concept-exercise-awareness-meditate-relax-calm-office-illustration_53562-13889.jpg" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white">
                            <div class="font-bold text-xl mb-2">Embrace the Power of Letting Go</div>
                            <p class="text-gray-700 text-base">
                                Release the weight of stress by embracing the power of letting go. 
                                Identify stressors that are beyond your control and practice acceptance. 
                                Focus on what you can change and release the rest. This shift in perspective 
                                can alleviate unnecessary burdens and promote a more relaxed mindset.
                            </p>
                        </div>
                    </div>
                    <!-- End -->

                    <!-- 2 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover h-48 w-96" src="https://img.freepik.com/free-vector/flat-design-people-doing-outdoor-activities_23-2149014849.jpg" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white">
                            <div class="font-bold text-xl mb-2">Engage in Physical Activity</div>
                            <p class="text-gray-700 text-base">
                                Physical exercise is a powerful tool for releasing built-up tension. 
                                Find an activity that brings you joy, whether it's jogging, dancing, 
                                or practicing a sport. Regular exercise not only improves your physical 
                                health but also releases endorphins, the body's natural stress relievers.
                            </p>
                        </div>
                    </div>
                    <!-- End -->

                    <!-- 3 -->
                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                        <div class="aspect-w-16 aspect-h-9">
                            <img class="object-cover h-48 w-96" src="https://img.freepik.com/free-vector/artist-painting-his-thoughts-canvas_52683-38064.jpg?size=626&ext=jpg&ga=GA1.1.34264412.1699660800&semt=ais" alt="Sunset in the mountains">
                        </div>
                        <div class="px-6 py-4 bg-white">
                            <div class="font-bold text-xl mb-2">Creative Outlets</div>
                            <p class="text-gray-700 text-base">
                                Explore creative activities as a means of expression and release. 
                                Whether it's writing, painting, or playing a musical instrument, 
                                engaging in creative pursuits allows you to channel emotions 
                                constructively and provides a therapeutic outlet for stress.
                            </p>
                        </div>
                    </div>
                    <!-- End -->
                </div>
            </div>
        </section>
        <div id="ad_id">
            <amp-ad width="100vw" height="320"
                type="adsense"
                data-ad-client="ca-pub-5580517504444924"
                data-ad-slot="8950603964"
                data-auto-format="rspv"
                data-full-width="">
                <div overflow=""></div>
            </amp-ad>
        </div>
    </div>


    <div class="bg-blue-500">
        <div class=" flex items-center justify-center">
            <div class="grid md:grid-cols-2 gap-4 mx-4 md:mx-20 mt-10 mb-5">
                <div class="my-5">
                    <BuddiesSVG/>
                </div>
                <div class="">
                    <a href="#" class="flex flex-row items-center h-full">
                        <div class="flex flex-col justify-between p-4 leading-normal w-full">
                            <h5 class="mb-3 text-2xl font-bold tracking-tight text-white">Embracing a Lifestyle of Serenity</h5>
                            <p class="mb-3 mx-2 font-normal text-gray-700 text-justify text-white">
                                In conclusion, "Relax, Relate, Release" is not just a catchy phrase; 
                                it's a blueprint for cultivating a lifestyle of serenity in a hectic 
                                world. Incorporating moments of relaxation, nurturing meaningful 
                                relationships, and releasing stress are key components of this transformative 
                                journey. By adopting these practices, you can create a harmonious balance 
                                between the demands of daily life and the pursuit of inner peace. So, 
                                take a deep breath, embrace the three Rs, and let serenity become a 
                                guiding principle in your journey towards holistic well-being.
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <PageFooter/>
</template>

<script>

import NavbarComp from '@/components/NavbarCompSecondary.vue'
import PageFooter from '@/components/PageFooter.vue'
import BuddiesSVG from '@/components/BuddiesSVG.vue'
import RelaxSVG from '@/components/RelaxSVG.vue'
import MedidatingSVG from '@/components/MeditatingSVG.vue'
import { useHead } from '@vueuse/head'

export default{
    components: {NavbarComp, PageFooter, BuddiesSVG, RelaxSVG, MedidatingSVG },
    name: 'RelaxPage',

    mounted(){
        const { meta } = this.$route;

        useHead({
            title: meta.title,
            meta: [
                { name: 'description', content: meta.description },
                { property: 'og:title', content: meta.ogTitle },
                { property: 'og:description', content: meta.ogDescription },
                { property: 'og:image', content: meta.ogImage },
                { property: 'og:url', content: meta.ogUrl },
            ],
        });
    },
}

</script>