<template>
    <NavbarComp></NavbarComp>
    <div class="bg-fixed bg-cover h-80 w-30" style="background-position: bottom;background-image: url('https://img3.wallspic.com/crops/8/3/4/3/4/143438/143438-aviation-airline-airplane-aerospaceengineering-sky-3840x2160.jpg')">
    </div>
    <div class="mt-10 mx-10 mb-10">
        <h1 class="my-heading text-left text-3xl font-bold">Outward Bound: Embracing the Journey Away from Home</h1>
        <p class="block text-justify text-black mt-5 mb-8">
            Traveling is not just about reaching a destination; it's a transformative journey that begins the moment 
            you step out of your comfort zone and venture into the unknown. The phrase "outward bound" encapsulates 
            the essence of this initial phase of exploration, where the excitement of discovery and the promise of 
            new experiences fuel the traveler's spirit.
        </p>
    </div>

    <div class="bg-blue-500">
        <div class="flex items-center justify-center">
            <div class="grid md:grid-cols-2 gap-4 mx-4 mt-5 mb-5">
                <div class="my-5 flex items-center justify-center">
                    <PlaneSVG/>
                </div>
                <div class="">
                    <a href="#" class="flex flex-row items-center h-full">
                        <div class="flex flex-col justify-between p-4 leading-normal w-full">
                            <h5 class="mb-3 text-5xl font-bold tracking-tight text-white text-center">Expedition Start</h5>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="grid md:grid-cols-2 gap-4 mx-4 md:mx-20 mt-10 mb-5">        
        <div class="w-full md:w-auto">
            <a href="#" class="flex flex-col items-center h-full bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100">
                <div class="flex flex-col justify-between p-4 leading-normal w-full">
                    <h5 class="mb-3 text-2xl font-bold tracking-tight text-gray-900">The Adventure Begins</h5>
                    <p class="mb-3 mx-2 font-normal text-gray-700 text-justify">
                        Every journey has its starting point, that moment when you leave the familiar behind and 
                        set out for destinations unknown. Whether it's boarding a plane, hopping on a train, or 
                        hitting the open road, the outward bound phase marks the commencement of your adventure. 
                        There's a unique thrill in knowing that the landscape outside your window is changing, and 
                        each passing mile brings you closer to uncharted territory.
                    </p>
                </div>
            </a>
        </div>
        <div class="w-full md:w-auto">
            <a href="#" class="flex flex-col items-center h-full bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100">
                <div class="flex flex-col justify-between p-4 leading-normal w-full">
                    <h5 class="mb-3 text-2xl font-bold tracking-tight text-gray-900">Break Routine</h5>
                    <p class="mb-3 mx-2 font-normal text-gray-700 text-justify">
                        Outward bound travel is a break from the routine of daily life. 
                        It's a chance to break free from the monotony, to step away from 
                        the ordinary and embrace the extraordinary. As you leave the familiar 
                        landmarks of home behind, you open yourself up to the possibility of 
                        new perspectives, cultures, and experiences.
                    </p>
                </div>
            </a>
        </div>
        <div class="w-full md:w-auto">
            <a href="#" class="flex flex-col items-center h-full bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100">
                <div class="flex flex-col justify-between p-4 leading-normal w-full">
                    <h5 class="mb-3 text-2xl font-bold tracking-tight text-gray-900">Learning and Growth</h5>
                    <p class="mb-3 mx-2 font-normal text-gray-700 text-justify">
                        Outward bound journeys are not just about physical movement; they're about personal growth. 
                        Stepping away from home allows you to learn about yourself, your strengths, and your ability 
                        to face challenges head-on. Each encounter, whether with a new culture, language, or 
                        landscape, contributes to your expanding worldview. It's a journey that educates, enlightens,
                         and enriches.
                    </p>
                </div>
            </a>
        </div>
        <div class="w-full md:w-auto">
            <a href="#" class="flex flex-col items-center h-full bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100">
                <div class="flex flex-col justify-between p-4 leading-normal w-full">
                    <h5 class="mb-3 text-2xl font-bold tracking-tight text-gray-900">The Outward Bound Spirit</h5>
                    <p class="mb-3 mx-2 font-normal text-gray-700 text-justify">
                        The spirit of being outward bound is not confined to the act of physically leaving home; 
                        it's a mindset that permeates the entire travel experience. It's about being open to new 
                        friendships, tasting local cuisines, and immersing yourself in the rhythm of different 
                        places. It's about collecting memories, not just souvenirs, and savoring the beauty of the 
                        journey as much as the destination.
                    </p>
                </div>
            </a>
        </div>
        <div id="ad_id">
            <amp-ad width="100vw" height="320"
                type="adsense"
                data-ad-client="ca-pub-5580517504444924"
                data-ad-slot="8950603964"
                data-auto-format="rspv"
                data-full-width="">
                <div overflow=""></div>
            </amp-ad>
        </div>
    </div>
    <div class="bg-yellow-400">
        <div class=" flex items-center justify-center">
            <div class="grid md:grid-cols-2 gap-4 mx-4 md:mx-20 mt-10">
                <div class="my-5">
                    <ExploreSVG/>
                </div>
                <div class="">
                    <a href="#" class="flex flex-col items-center h-full">
                        <div class="flex flex-col justify-between p-4 leading-normal w-full">
                            <h5 class="mb-3 text-2xl font-bold tracking-tight text-white">Homeward Bound: A Different Kind of Adventure</h5>
                            <p class="mb-3 mx-2 font-normal text-gray-700 text-justify text-white">
                                As the outward bound adventure unfolds, it sets the stage for the eventual return journey. The experiences accumulated, the lessons learned, and the memories made all shape the person returning home. Homeward bound, though a different kind of adventure, is no less significant. It's a chance to reflect on the journey, carry the newfound wisdom, and perhaps appreciate the familiar with fresh eyes.
                                <br> <br>In conclusion, outward bound travel is not just a physical movement from point A to point B; it's a mindset, a spirit, and a philosophy. It's about embracing the journey, relishing the unknown, and letting the adventure shape you. So, the next time you find yourself outward bound, savor every moment, for it's not just a trip—it's a transformative experience that lingers long after you've returned home.
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <PageFooter/>
</template>

<script>

import NavbarComp from '@/components/NavbarCompSecondary.vue'
import PlaneSVG from '@/components/PlaneSVG.vue'
import PageFooter from '@/components/PageFooter.vue'
import ExploreSVG from '@/components/ExploreSVG.vue'
import { useHead } from '@vueuse/head'

export default{
    components: {NavbarComp, PlaneSVG, PageFooter, ExploreSVG },
    name: 'OutwardBound',

    mounted(){
        const { meta } = this.$route;

        useHead({
            title: meta.title,
            meta: [
                { name: 'description', content: meta.description },
                { property: 'og:title', content: meta.ogTitle },
                { property: 'og:description', content: meta.ogDescription },
                { property: 'og:image', content: meta.ogImage },
                { property: 'og:url', content: meta.ogUrl },
            ],
        });
    },
}

</script>